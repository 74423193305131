import React from "react"
import Icon from "../Icon"

const Button = ({bsStyle, type, disabled, text}) => {
    if(disabled === true) {
        return (
            <button id="contact-submit" className={"btn btn-" + bsStyle} type={type} disabled>{text}<Icon name="spinner" spin="true" /></button>
        )
    } else {
        return (
            <button id="contact-submit" className={"btn btn-" + bsStyle} type={type}>{text}</button>
        )
    }
}

export default Button