import React from "react"
import Message from "../Message"
import Recaptcha from "react-recaptcha"
import Button from "../Button"
import axios from "axios"

import "./styles.scss"

const formAPI = process.env.API_CONTACT_FORM
let recaptchaInstance;

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            disableSubmit: false,
            showMessage: false,
        }
    }

    validateInputs = () => {
        let ok = true;
        this.setState({errors: {}})
        if (!this.state.name) {
            this.setState(({errors}) => ({errors: {
            ...errors,
            name: "Please enter a valid name",
            }}))
            ok = false;
        }
        if (!this.state.reCaptcha) {
            this.setState(({errors}) => ({errors: {
            ...errors,
            reCaptcha: "Please verity you are a human",
            }}))
            ok = false;
        }
        if (!this.isEmail(this.state.email)) {
            this.setState(({errors}) => ({errors: {
            ...errors,
            email: "Please enter a valid e-mail",
            }}))
            ok = false;
        }
        return ok;
    }

    isEmail = e => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(e);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.validateInputs() === false) {
            return
        } else {
            this.setState({
                disableSubmit: true,
                showMessage: false
            })
            axios.post(formAPI,
            {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                enquiry: this.state.enquiry,
                reCaptcha: this.state.reCaptcha,
            })
            .then(response => {
                this.setState({
                    disableSubmit: false,
                    showMessage: true,
                    message: response.data.message,
                    type: "success"
                })
                this.resetRecaptcha()
            })
            .catch(error => {
                this.setState({
                    disableSubmit: false
                })
                if (error.response) {
                    this.setState({
                        showMessage: true,
                        message: error.response.data.message,
                        type: "danger"
                    })
                    this.resetRecaptcha()
                } else if (error.request) {
                    this.setState({
                        showMessage: true,
                        message: "Server Error",
                        type: "danger"
                    })
                    this.resetRecaptcha()
                }
            })
        }
    }

    handleChange = e => this.setState({ [e.target.name]: e.target.value })

    verifyReCaptcha = response => this.setState({"reCaptcha": response})

    resetRecaptcha = () => recaptchaInstance.reset();  

    render() {
        return(
            <>
            <form type="multipart/form-data" onSubmit={this.handleSubmit}>
                <div className="row">
                    <div className="col-lg-6 form-col-1">
                        <div className="form-group">
                            <input onChange={this.handleChange} name="name" className={this.state.errors.name ? "form-control is-invalid" : "form-control"} id="name" aria-describedby="nameHelp" placeholder="Name" />
                            <Feedback message={this.state.errors.name} />
                        </div>
                        <div className="form-group">
                            <input onChange={this.handleChange} name="email" className={this.state.errors.email ? "form-control is-invalid" : "form-control"}  id="email" aria-describedby="emailHelp" placeholder="E-Mail" />
                            <Feedback message={this.state.errors.email} />
                        </div>
                        <div className="form-group">
                            <input onChange={this.handleChange} name="phone" className="form-control" id="phone" aria-describedby="phoneHelp" placeholder="Phone" />
                        </div>
                    </div>
                    <div className="col-lg-6 form-col-2">
                        <div className="form-group">
                            <textarea onChange={this.handleChange} name="enquiry" className="form-control" id="enquiry" rows="6" placeholder="Message"></textarea>
                        </div>
                        <div className="form-group">
                            <Recaptcha
                                ref={e => recaptchaInstance = e}
                                sitekey={process.env.GOOGLE_RECAPTCHA_KEY}
                                verifyCallback={this.verifyReCaptcha}
                            />
                        <Feedback message={this.state.errors.reCaptcha} />
                        </div>
                        <div className="form-group">
                            <Message show={this.state.showMessage} type={this.state.type} message={this.state.message} />
                        </div>
                        <div className="form-group">
                            <Button bsStyle="primary" type="submit" disabled={this.state.disableSubmit} text="Send" />
                        </div>
                    </div>
                </div>
            </form>
            </>
        )
    }
}

const Feedback = ({message}) => {
    return (
        <div className="form-invalid">
            {message}
        </div>
    )
}

export default ContactForm