import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import GoogleMaps from "../components/GoogleMaps"
import ContactForm from "../components/ContactForm"

class Contact extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {
        showInfoWindow: true
    }
  }

  toggleInfoWindow = () => this.setState({showInfoWindow: !this.state.showInfoWindow })

  render() {
    return(
      <Layout location={this.props.location} >
        <SEO title="Contact" />
        <div className="row">
          <div className="col-md-8 content-left">
            <h1>Contact Us</h1>
            <div className="contact-map">
              <GoogleMaps 
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                mapCenter={{ lat: -34.890568, lng: 150.594808 }}
                isMarkerShown
                markerCenter={{ lat: -34.890568, lng: 150.594808 }}
                zoom={17}
                toggleInfoWindow={this.toggleInfoWindow}
                showInfoWindow={this.state.showInfoWindow}
                infoWindowData={
                    <>
                    <h5 className="googlemaps-heading">M.L. Wray Automotive</h5>
                    <p className="googlemaps-text">Unit 2/55 Albatross Road<br/>
                    Nowra NSW 2541</p>
                    </>
                }
              />
            </div>
            <ContactForm />
          </div>
          <div className="col-md-4 content-right">
            <h1>Operating Hours</h1>
            <h3>Workshop:</h3>
            <p>Monday - Friday: 8am - 5pm<br/>
              Saturday &amp; Sunday: Closed</p>
            <p><strong>Towing Service: 24/7</strong></p>
            <p>Licence No: MVRL24142</p>
            <p>ABN: 27 927 546 844</p>
          </div>
        </div>
      </Layout>
    )
  }
 
}

export default Contact